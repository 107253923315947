import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Loader from 'react-loader-spinner';
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { clientProfile } from '../../helpers/api';
// eslint-disable-next-line
import TezosLogo from "../../components/Common/TezosLogo";
import EthereumLogo from "../../components/Common/EthereumLogo";
import PolygonLogo from "../../components/Common/PolygonLogo";

class Info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            client: {},
            loading: true,
            tokenTezos: null,
            tokenEthereum: null,
            tokenPolygon: null,
        };
    }

    toggleCredentialsModal = () => {
        this.setState({ credentialsModal: !this.state.credentialsModal })
    }

    loadClient = async () => {
        this.setState({ loading: true })
        const res = await clientProfile()
        this.setState({
            client: res.data.client, tokenTezos: res.data.fa2, tokenEthereum: res.data.erc20, tokenPolygon: res.data.erc20polygon, loading: false
        })
    }

    componentDidMount = () => {
        this.loadClient()
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        {this.state.loading ?
                            <Loader type="Puff" color="#545454" height={50} width={50} />
                            :
                            <Row>
                                <Col xl="4">
                                    <Card className="overflow-hidden">
                                        <CardBody className="pt-2">
                                            <Row>

                                                {/* <Col sm="12">
                                                    <h5 className="font-size-15 text-truncate mt-4"><TezosLogo/> Tezos FA2 Contract</h5>
                                                    <p className="text-muted mb-0 text-truncate p-relative">
                                                        {this.state.tokenTezos}
                                                        <br/>
                                                        <small><Link target={"_blank"} to={{ pathname:"https://gitlab.com/sceme/salvus/eurus-tezos"}}>Git repository</Link> | <Link target={"_blank"} to={{ pathname:`https://ghostnet.tzkt.io/${this.state.tokenTezos}`}}>Block explorer</Link></small>
                                                    </p>
                                                </Col> */}

                                                <Col sm="12">
                                                    <h5 className="font-size-15 text-truncate mt-4"><EthereumLogo/> Ethereum ERC20 Contract</h5>
                                                    <p className="text-muted mb-0 text-truncate p-relative">
                                                        {this.state.tokenEthereum}
                                                        <br/>
                                                        <small><Link target={"_blank"} to={{ pathname:"https://gitlab.com/sceme/salvus/eurus-solidity"}}>Git repository</Link> | <Link target={"_blank"} to={{ pathname:`https://goerli.etherscan.io/address/${this.state.tokenEthereum}`}}>Block explorer</Link></small>
                                                    </p>
                                                </Col>

                                                <Col sm="12">
                                                    <h5 className="font-size-15 text-truncate mt-4"><PolygonLogo/> Polygon ERC20 Contract</h5>
                                                    <p className="text-muted mb-0 text-truncate p-relative">
                                                        {this.state.tokenPolygon}
                                                        <br/>
                                                        <small><Link target={"_blank"} to={{ pathname:"https://gitlab.com/sceme/salvus/eurus-solidity"}}>Git repository</Link> | <Link target={"_blank"} to={{ pathname:`https://goerli.etherscan.io/address/${this.state.tokenEthereum}`}}>Block explorer</Link></small>
                                                    </p>
                                                </Col>
                                                <hr/>
                                                <Col sm="12">
                                                    <h5 className="font-size-15 text-truncate mt-4">SCHUMAN Financial IBAN (where to send funds)</h5>
                                                    <p className="text-muted mb-0 text-truncate p-relative">
                                                        {this.state.client.receivingBank}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user } = state.Login;
    return { user };
}

export default withRouter(connect(mapStatetoProps, null)(withTranslation()(Info)));
